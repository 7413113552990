import { AxiosError } from 'axios';
import { useMutation, useQuery, UseQueryOptions } from 'react-query';

import { easApi, DirectMethodResponse } from './easApi';
import { queryClient } from '../../config/queryConfig';

export interface DeviceData {
  deviceName: string
  deviceStatus: string
  customer?: string
  deployment?: string
  city?: string
  countryCode?: string
  description?: string
  geoLocation?: string
}

const useGetDevice = (deviceId?: string, queryOptions?: UseQueryOptions<DeviceData, AxiosError>) => useQuery<DeviceData, AxiosError>(
  ['device', deviceId],
  () => easApi.getDevice(deviceId), {
    ...queryOptions
  });

const useGetDevices = () => useQuery<DeviceData[], Error>({
  queryKey: ['getDevices'],
  queryFn: () => easApi.getDevices()
})

export interface Deployments{
  id: string
  targetCondition: string
}

const useGetDeployments = () => useQuery<Deployments[], Error>({
  queryKey: ['getDeployments'],
  queryFn: () => easApi.getDeployments()
});

export interface ModuleData {
  moduleName: string
  moduleId: string
  connectionState: string
  deploymentType: string
  moduleType: string
  status: string
  version: string
  appMessage?: string
  appStatus?: string
  confStatus?: string
  desiredConfId?: string
  reportedConfId?: string
}

const useGetModules = (deviceId: string | undefined) => useQuery<ModuleData[], Error>({
  queryKey: ['getModules', deviceId],
  queryFn: () => easApi.getModules(deviceId),
  refetchInterval: 8000
});

interface ConnectionStatusResponse {
  iotEdgeRuntime: string
  iotHub: string
  sems: string
  vpn: string
}

const useGetDeviceConnectionStatus = (deviceId: string | undefined) => useQuery<ConnectionStatusResponse, AxiosError>({
  queryKey: ['getConnectionStatus', deviceId],
  queryFn: () => easApi.getConnectionStatus(deviceId)
});

export interface SmartEMSDescription {
  description: string
}
const usePostSmartEmsDescription = (deviceId: string | undefined) => useMutation<unknown, AxiosError, SmartEMSDescription>({
  mutationFn: (payload: SmartEMSDescription) => easApi.postSmartEMSDescription(deviceId, payload),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ["getSmartEmsInfo", "device"] })
})

interface IotHubDeviceTagsUpdate {
  deviceTags: {
    [key: string]: string;
  }
}

const usePostDeviceTags = (deviceId: string) => useMutation({
  mutationFn: (payload: IotHubDeviceTagsUpdate) => easApi.postDeviceTags(deviceId, payload),
  onSuccess: () => queryClient.invalidateQueries({ queryKey: ["getDevices", "device" ] })
});


export interface EdgeDeviceTagUpdate {
  countryCode?: string
  city?: string
  geoLocation?: string
  customer?: string
  description?: string
}

export interface TwinConfig {
  subscriptions: Subscription[]
}

export interface Subscription {
  subscriptionName: string,
  requestedPublishingInterval: number,
  requestedLifetimeCount: number,
  requestedMaxKeepAliveCount: number,
  maxNotificationsPerPublish: number,
  publishingEnabled: boolean,
  priority: number,
  monitoredItemSamplingInterval: number,
  monitoredItemQueueSize: number,
  monitoredItemDiscardOldest: boolean,
  mqttOutput: {
    topic: string,
    messageFormat: string,
    qos: number
  },
  edgeHubOutput: {
    topic: string,
    messageFormat: string,
    applicationData: Object
  },
  nodeIds: string[] | {
    [nodeId: string]: {
      alias: "string" | null,
      filter: "string" | null
    }
  }
}

// OPCUA Client
const useGetTwinConfig = <SelectType = TwinConfig>(deviceId: string | undefined, moduleName: string | undefined, queryOptions?: UseQueryOptions<TwinConfig, AxiosError, SelectType>) =>
  useQuery<TwinConfig, AxiosError, SelectType>(
    ['getTwinConfig', deviceId, moduleName],
    () => easApi.getTwinConfig(deviceId, moduleName), {
    ...queryOptions
  }
);

// OPCUA Browser
interface ReadResult {
  message: string,
  nodeId: {
    nodeId: string
  },
  status: boolean,
  value: {
    dataType: string,
    value: any
  }
}
const useReadNodeId = <SelectType = ReadResult>(deviceId: string | undefined, connectionParams: Object, nodeId: string, queryOptions?: UseQueryOptions<DirectMethodResponse<ReadResult>, AxiosError, SelectType>) =>
  useQuery<DirectMethodResponse<ReadResult>, AxiosError, SelectType>(
    ['readNodeId', deviceId, connectionParams, nodeId],
    () => {
      const payload = {
        "methodName": "readNodeId",
        "methodPayload":
        {
          "schemaVersion": "1.0",
          ...connectionParams,
          "nodeId": nodeId
        }
      }
      return easApi.invokeDirectMethod(deviceId, "gea-app-opcua-browser", payload);
    }, {
      select: (data) => data.payload as SelectType,
      ...queryOptions,
    });


export interface BrowseResult {
  statusCode: number,
  references: {
    referenceTypeId: string,
    isForward: boolean,
    nodeId: string
    browseName: {
      namespaceIndex: number,
      name: string
    },
    displayName: {
      text: string
    },
    nodeClass: string,
  }[]
}
const useBrowseNodeId = (deviceId: string | undefined, connectionParams: Object, nodeId: string) => useQuery<DirectMethodResponse<BrowseResult>, AxiosError, BrowseResult>({
  queryKey: ['browseNodeId', deviceId, connectionParams, nodeId],
  queryFn: () => {
    const payload = {
      "methodName": "browseNodeId",
      "methodPayload":
      {
        "schemaVersion": "1.0",
        ...connectionParams,
        "nodeId": nodeId
      }
    }
    return easApi.invokeDirectMethod(deviceId, "gea-app-opcua-browser", payload);
  },
  select: (data) => data.payload,
});
const useBrowseNodeIds = (deviceId: string | undefined, connectionParams: Object, nodeIds: string[]) => useQuery<DirectMethodResponse<BrowseResult[]>, AxiosError, BrowseResult[]>({
  queryKey: ['browseNodeIds', deviceId, connectionParams, nodeIds],
  queryFn: () => {
    const payload = {
      "methodName": "browseNodeIds",
      "methodPayload":
      {
        "schemaVersion": "1.0",
        ...connectionParams,
        "nodeIds": nodeIds
      }
    }
    return easApi.invokeDirectMethod(deviceId, "gea-app-opcua-browser", payload);
  },
  select: (data) => data.payload,
  staleTime: Infinity
});

export interface SmartEmsData {
  enabled: boolean
  lastSeenAt: string
  hardwareVersion: string
  updateFirmware: boolean
  semsTemplate: string
  firmwareVersion: string
  template: { toString: string }
  description: string
  variables: [{toString: string, variableValue: string}]
}
const useGetSmartEmsInfo = (deviceId: string | undefined) => useQuery<SmartEmsData, AxiosError>({
  queryKey: ['getSmartEmsInfo', deviceId],
  queryFn: () => easApi.getSmartEmsInfo(deviceId)
});

export interface MonitorItemsResult {
  connectionId: string
}
const useMonitorItems = <SelectType = MonitorItemsResult>(deviceId: string | undefined, connectionParams: Object, nodeIds: string[], queryOptions?: UseQueryOptions<DirectMethodResponse<MonitorItemsResult>, AxiosError, SelectType>) =>
  useQuery<DirectMethodResponse<MonitorItemsResult>, AxiosError, SelectType>(
  ['monitorItems', deviceId, connectionParams, nodeIds],
  () => {
    const payload = {
      "methodName": "monitorItems",
      "methodPayload":
      {
        "schemaVersion": "1.0",
        ...connectionParams,
        "nodeIds": nodeIds
      }
    }
    return easApi.invokeDirectMethod(deviceId, "gea-app-opcua-browser", payload);
  },
  {
    select: (data) => data.payload as SelectType,
    ...queryOptions
  }
);

export const easApiHooks = {
  useGetDevice,
  useGetDevices,
  useGetSmartEmsInfo,
  useGetDeviceConnectionStatus,
  useGetDeployments,
  useGetModules,
  useGetTwinConfig,
  usePostDeviceTags,
  useReadNodeId,
  useBrowseNodeId,
  useBrowseNodeIds,
  useMonitorItems,
  usePostSmartEmsDescription
}